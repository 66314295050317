import { defineStore } from "pinia";
import { Notification, TriggerType } from "~/types/notifications";

export const useNotificationsStore = defineStore("notifications", () => {
  // Sidebar state
  const isOpenSidebar = ref(false);

  // Notifications list
  const notifications = ref<Notification[]>([]);
  const isOrderNotificationSent = ref(false);
  const isOpenNotificationModal = ref<boolean>(false);

  // Trigger types
  const triggersList = ref([
    { name: "New Order", value: TriggerType.NEW_ORDER },
    { name: "Order Status Change", value: TriggerType.ORDER_STATUS_CHANGE },
    { name: "Order Type Change", value: TriggerType.ORDER_TYPE_CHANGE },
    {
      name: "Order Xero Invoice Created",
      value: TriggerType.ORDER_XERO_INVOICE_CREATED,
    },
    { name: "Order Note Created", value: TriggerType.ORDER_NOTE_CREATED },
    { name: "Customer Note Created", value: TriggerType.CUSTOMER_NOTE_CREATED },
    {
      name: "Notify New Online Order",
      value: TriggerType.NOTIFY_NEW_ONLINE_ORDER,
    },
    {
      name: "Order Confirmation",
      value: TriggerType.ORDER_CONFIRMATION,
    },
  ]);

  // Merge tags
  const mergeTagsList = ref([
    "User Email",
    "User Name",
    "Default Store",
    "Location",
    "Customer",
    "Order Status",
    "Order Type",
    "Order Number",
    "Order Details",
    "Login Link",
    "Delivery Address",
    "Billing Address",
    "Statement",
    "Period",
    "Due Date",
    "Payment Link",
    "Deposit Amount",
    "Invoice Link",
    "Receipt Link",
    "Bill Link",
    "Email",
    "Received Date",
    "Onboard Link",
    "Outstanding Statement",
    "Activities Statement",
    "Payback Summary",
  ]);

  const mergeTagsListNew = ref([
    { name: "User Email", value: "{User Email}" },
    { name: "User Name", value: "{User Name}" },
    { name: "Default Store", value: "{Default Store}" },
    { name: "Location", value: "{Location}" },
    { name: "Customer", value: "{Customer}" },
    { name: "Order Status", value: "{Order Status}" },
    { name: "Order Type", value: "{Order Type}" },
    { name: "Order Number", value: "{Order Number}" },
    { name: "Order Details", value: "{Order Details}" },
    { name: "Login Link", value: "{Login Link}" },
    { name: "Delivery Address", value: "{Delivery Address}" },
    { name: "Billing Address", value: "{Billing Address}" },
    { name: "Statement", value: "{Statement}" },
    { name: "Period", value: "{Period}" },
    { name: "Due Date", value: "{Due Date}" },
    { name: "Payment Link", value: "{Payment Link}" },
    { name: "Deposit Amount", value: "{Deposit Amount}" },
    { name: "Invoice Link", value: "{Invoice Link}" },
    { name: "Receipt Link", value: "{Receipt Link}" },
    { name: "Bill Link", value: "{Bill Link}" },
    { name: "Email", value: "{Email}" },
    { name: "Received Date", value: "{Received Date}" },
    { name: "Onboard Link", value: "{Onboard Link}" },
    { name: "Outstanding Statement", value: "{Outstanding Statement}" },
    { name: "Activities Statement", value: "{Activities Statement}" },
    { name: "Payback Summary", value: "{Payback Summary}" },
  ]);

  // DEFAULT TEMPLATES
  const orderNotificationDefaultTemplate = ref({
    name: "Custom Order Notification",
    id: 9999,
    email_subject: "Order Notification {Order Number}",
    email_content:
      "Dear {Customer},<p></p>Your order is being prepared, a team member from {Location} will be in touch to confirm your delivery details.<p></p>Here is a summary of your order - <p></p>{Order Details}<p></p>Thank you,<p></p>Team Jaffle",
    sms: false,
    sms_content: "",
  });

  const orderNotificationDefaultOldTemplate = ref({
    name: "Custom Order Notification",
    id: 9998,
    email_subject: "Order Notification [Order Number]",
    email_content:
      "Dear [Customer],\n\nYour order is being prepared, a team member from [Location] will be in touch to confirm your delivery details -\n\nHere is a summary of your order -\n\n[Order Details]\n\nThank you,\nTeam Jaffle\n",
    sms: false,
    sms_content: "",
  });

  const purchaseNotificationDefaultTemplate = ref({
    name: "Custom Purchase Notification",
    id: 9999,
    email_subject: "Purchase Order Notification {Order Number}",
    email_content:
      "Dear {Supplier},<p></p>Your order is being prepared, a team member from {Location} will be in touch to confirm your delivery details.<p></p>Here is a summary of your order - <p></p>{Order Details}<p></p>Thank you,<p></p>Team Jaffle",
    sms: false,
    sms_content: "",
  });

  const servicesNotificationDefaultTemplate = ref({
    name: "Service Agreement",
    id: 8888,
    email_subject: "Order Confirmation {Order Number}",
    email_content:
      "Hi {Customer},<p></p>Thank you for your order.<p></p>{Order Details}<p></p>{Download}<p></p>",
    sms: false,
    sms_content: "",
  });

  const sendInvoiceDefaultTemplate = ref({
    name: "Custom Invoice Notification",
    id: 7777,
    email_subject: "Invoice [Order Number]",
    email_content:
      "Dear {User Name},<p></p>Please find attached your statement. Payment is due on {Due Date}.",
    sms: true,
    sms_content:
      "Dear {User Name},<p></p>Please find attached your statement. Payment is due on {Due Date}.",
  });

  const sendBillDefaultTemplate = ref({
    name: "Custom Bill Notification",
    id: 6666,
    email_subject: "Bill",
    email_content:
      "Dear {User Name},<p></p>Please find attached your bill. We expect to process your payment on {Due Date}.",
    sync_with_xero: true,
    sms: true,
    sms_content:
      "Dear {User Name},<p></p>Please find attached your bill. We expect to process your payment on {Due Date}.",
  });

  // Platform notifications by trigger
  const isPlatformNotificationByTrigger = ref([
    TriggerType.PASSWORD_REQUEST,
    TriggerType.ACCOUNT_CONFIRMATION,
    TriggerType.RECEIPT_REQUEST,
    TriggerType.PAYMENT_LINK_REQUEST,
    TriggerType.DEPOSIT_REQUEST,
    TriggerType.EVENT_REMINDER,
    TriggerType.EVENT_INVITATION,
    TriggerType.SEND_STATEMENT,
    TriggerType.SEND_ONBOARD_INVITE,
    TriggerType.SEND_ONBOARD_STAFF_INVITE,
    TriggerType.SEND_ADMIN_NOTIFICATION_AFTER_ONBOARDING,
    TriggerType.MFA_CHALLENGE,
    TriggerType.PROFILE_APPROVED,
    TriggerType.BOOKING,
    TriggerType.NOTIFY_NEW_ONLINE_ORDER,
  ]);

  // Getters
  const platformNotifications = computed(() =>
    notifications.value.filter(
      (notification) =>
        (notification.triggers.length === 1 &&
          isPlatformNotificationByTrigger.value.includes(
            notification.triggers[0].type,
          )) ||
        notification.triggers.some(
          (trigger) => trigger.type === TriggerType.NOTIFY_NEW_ONLINE_ORDER,
        ),
    ),
  );

  const customNotifications = computed(() =>
    notifications.value.filter(
      (notification) =>
        (!notification.triggers.length ||
          (notification.triggers.length === 1 &&
            !isPlatformNotificationByTrigger.value.includes(
              notification.triggers[0].type
            )) ||
          notification.triggers.length > 1) &&
        !notification.triggers.some(
          (trigger) => trigger.type === TriggerType.NOTIFY_NEW_ONLINE_ORDER
        )
    )
  );

  // Actions
  async function getNotifications() {
    useIsLoading(true);
    try {
      const response = (await useVaniloApi("/notifications")) as any;

      notifications.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function getNotification(notificationId) {
    useIsLoading(true);
    try {
      const response = (await useVaniloApi(
        `/notifications/${notificationId}`
      )) as any;

      notifications.value = response?.data;

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function createNotification(payload) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi("/notifications", {
        method: "POST",
        body: payload,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function updateNotification(payload) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi(`/notifications/${payload.id}`, {
        method: "POST",
        body: payload,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function createOrderNotification(payload) {
    const route = useRoute();

    const isPurchase = [
      useRoutesNames().purchaseOrders,
      useRoutesNames().purchaseOrdersManageId,
      useRoutesNames().purchaseOrdersPurchaseOverview,
    ].includes(route.name as any);

    const orderId = payload.orderId;

    delete payload.orderId;

    useIsLoading(true);

    if (isPurchase) {
      try {
        const response = await useVaniloApi(
          `/notifications/send-custom-purchase-order/${orderId}`,
          {
            method: "POST",
            body: payload,
          }
        );

        isOrderNotificationSent.value = true;

        return response;
      } catch (error) {
        useCatchError(error);
      } finally {
        useIsLoading(false);
      }
    }

    try {
      const response = await useVaniloApi(
        `/notifications/send-custom-order/${orderId}`,
        {
          method: "POST",
          body: payload,
        }
      );

      isOrderNotificationSent.value = true;

      return response;
    } catch (error) {
      useErrorNotifications(error);
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function createOrderNotificationNew(orderId, payload) {
    useIsLoading(true);

    try {
      const response = await useVaniloApi(
        `/notifications/new-send-custom-order/${orderId}`,
        {
          method: "POST",
          body: payload,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function createPurchaseOrderNotificationNew(orderId, payload) {
    useIsLoading(true);

    try {
      const response = await useVaniloApi(
        `/notifications/new-send-custom-purchase-order/${orderId}`,
        {
          method: "POST",
          body: payload,
        }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function createCustomerNotification(customerId, payload) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi(
        `/notifications/send-custom/${customerId}`,
        {
          method: "POST",
          body: payload,
        }
      );

      isOrderNotificationSent.value = true;

      return response;
    } catch (error) {
      useErrorNotifications(error);
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function createCustomerUserNotification(userId, payload) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi(
        `/notifications/send-custom-user/${userId}`,
        {
          method: "POST",
          body: payload,
        }
      );

      isOrderNotificationSent.value = true;

      return response;
    } catch (error) {
      useErrorNotifications(error);
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function deleteNotifications(notificationId) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi(`/notifications/${notificationId}`, {
        method: "DELETE",
      });

      return response;
    } catch (error) {
      useCatchError(error);
    } finally {
      useIsLoading(false);
    }
  }

  return {
    // State
    isOpenSidebar,
    notifications,
    isOrderNotificationSent,
    isOpenNotificationModal,
    triggersList,
    mergeTagsList,
    mergeTagsListNew,
    sendInvoiceDefaultTemplate,
    sendBillDefaultTemplate,
    orderNotificationDefaultTemplate,
    orderNotificationDefaultOldTemplate,
    purchaseNotificationDefaultTemplate,
    servicesNotificationDefaultTemplate,
    isPlatformNotificationByTrigger,
    // Getters
    platformNotifications,
    customNotifications,
    // Actions
    getNotifications,
    getNotification,
    createNotification,
    updateNotification,
    createOrderNotification,
    createCustomerNotification,
    createCustomerUserNotification,
    deleteNotifications,
    createOrderNotificationNew,
    createPurchaseOrderNotificationNew,
  };
});
